const TextPost = ({ data, id }) => {
    const { date, title, content } = data;

    return <div className='text-post' id={id}>
                <div className='text-upper'>
                    <h1 className='text-title'>{title}</h1>
                    <h2 className='text-date'>{date}</h2>
                </div>
                <div className='text-lower'>
                    <p className='text-content'>{content}</p>
                </div>
        </div>;
}

export default TextPost;