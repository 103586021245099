import { Carousel } from "react-responsive-carousel";

const ImagePost = ({ data, id }) => {
    const { date, title, content, images } = data;

    return <div className='image-post' id={id}>
                <div className='image-upper'>
                    <h1 className='image-title'>{title}</h1>
                    <h2 className='image-date'>{date}</h2>
                </div>
                {images.length === 1 ? (
                    <div className='image-lower'>
                        <div className='post-image-container'>
                            <img src={images[0]} alt='artifactImage' className='post-image'/>
                            <div className='image-content'>{content}</div>
                        </div>
                    </div>
                ) : (
                    <div className='image-lower'>
                        <Carousel showThumbs={false} showStatus={false} showIndicators={true} infiniteLoop={true} autoPlay={false} swipeable={false} emulateTouch={true} dynamicHeight={false} useKeyboardArrows={true} centerMode={true} centerSlidePercentage={100} className='artifact-carousel'
                            renderArrowPrev={(clickHandler, hasPrev) => {
                                return (
                                <div onClick={clickHandler} className="carousel-arrow carousel-arrow-left bounce-left">
                                    <a className="fa fa-arrow-left fa-2x"></a>
                                </div>
                                );
                            }}
                            renderArrowNext={(clickHandler, hasNext) => {
                                return (
                                <div onClick={clickHandler} className="carousel-arrow carousel-arrow-right bounce-right">
                                    <a className="fa fa-arrow-right fa-2x"></a>
                                </div>
                                );
                            }}>
                                {images.map((image, index) => {
                                    return (
                                        <div key={index} className='inner-wrapper'>
                                            <img src={image} alt='artifactImage' className='post-image'/>
                                        </div>)
                                })}
                            </Carousel>
                        <div className='image-content'>{content}</div>
                    </div>
                )}
            </div>
        }

export default ImagePost;